<template>
  <div class="preview-box">
    <div class="preview-box-inner">
      <v-row class="pt-5">
        <v-col class="col-4">
          <img width="280px" src="../assets/logo.png" alt="" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12">
          <h1>TERMS AND CONDITIONS FOR THE SUPPLY OF SERVICES</h1>
          <h4 class="pt-5">1. INTERPRETATION</h4>
          <p>
            The definifions and rules of interpretation in this Condition apply in these Conditions.
          </p>
          <p>
            <strong>Acknowledgement of Order</strong>: a Written acknowledgment from the Company to the Customer detailing the Services to be supplied by the Company and the Charges applicable.
          </p>
          <p>
            <strong>Charges</strong>: the charges to be applied in relation to the Services consisting of either:
          </p>
          <ul>
            <li>
              the Minimum Charge as detailed in clause 5.5 subject always to a Remeasure as detailed in clause 5.4;  or
            </li>
            <li>
              a Day Rate as detailed in clause 5.6; or
            </li>
            <li>
              a Fixed Price as detailed in clause 5.7.
            </li>
            <li>
              together with any further Charge variation for the reasons set out in clauses 5.8 to 5.16.
            </li>            
          </ul>
          <p class="pt-5">
            <strong>Confidential Information</strong>: Any information disclosed by one (the disclosing party) to another (the receiving party) if the disclosing party has notified the receiving party that the information is confidential, or the information could reasonably be supposed to be confidential.
          </p>
          <p>
            <strong>Customer</strong>: the person, firm or company who purchases the Services from the Company.
          </p>
          <p>
            <strong>Company</strong>: Riggott and Company Limited -  Company Number 02985947 r/o Unit X, Lodge Lane, Tuxford, Newark Notts NG22 0NL.
          </p>
          <p>
            <strong>Contract</strong>: any contract between the Company and the Customer for the Supply of Products and Services, incorporating these Conditions.
          </p>
          <p>
            <strong>Conditions</strong>: these Terms and Conditions of Supply.
          </p>
          <p>
            <strong>Day Rate</strong>: a fixed sum agreed as the Charges for the provision of the Services for the exclusive provision of one crew for one day based upon 10 hours work and reasonable travelling which shall be specifically identified in the Acknowledgment of Order.
          </p>
          <p>
            <strong>Document</strong>: any document in writing, and any map, plan, graph, drawing or photograph and any film, negative tape or other device or document embodying visual images and any disc, tape or other device or document embodying any other data.
          </p>
          <p>
            <strong>Fixed Price</strong>: a fixed sum agreed as the Charges for the provision of the Services which shall be specifically identified in the Acknowledgment of Order.
          </p>
          <p>
            <strong>Input Material</strong>: any Documents or other materials, and any data or other information provided to the Company by the Customer relating to the Services including information as to the type and quality of the surface onto which the Products are to be applied.
          </p>
          <p>
            <strong>Intellectual Property Rights</strong>: any design rights, utility models, patents, inventions, logos, business names, trademarks, domain names, copyright, moral rights, rights in databases, source codes, reports, drawings, specifications, know how, trade secrets, rights in software, rights in the nature of unfair competition and the right to sue for passing off and any other equivalent or similar rights to any of the foregoing in any jurisdiction, whether registered or unregistered.
          </p>
          <p>
            <strong>Minimum Charge</strong>: the Company’s mobilisation charge in relation to the Supply of the Services as set out in the Quotation and Acknowledgment of Order which is payable in all cases, and as detailed in clause 5.5.
          </p>
          <p>
            <strong>Products</strong>: any paint or other Marking Materials supplied by the Company in the delivery of the Services.
          </p>
          <p>
            <strong>Output Material</strong>: any Documents or other materials including, without limitation, any data or other information provided by the Company to the Customer relating to the Services.
          </p>
          <p>
            <strong>Personal Data:</strong> Any data which relates to a living individual who may be identified from such  data.
          </p>
          <p>
            <strong>Quotation</strong>: the Company’s written quotation providing details of the Services to be supplied and the Charges to be applied. 
          </p>
          <p>
            <strong>Services</strong>: any services agreed in the Contract to be performed by the Company for the Customer (including any part or parts of them) including but not limited to any line marking services, surface coating services, cleaning services and/or small civil engineering works as set out in the Acknowledgement of Order.
          </p>
          <p>
            <strong>Site</strong>: the place where the Services are to be performed, including but not limited to the Customer’s premises.
          </p>
          <p>
            <strong>Supply</strong>: the provision of any Services by the Company to the Customer. For the avoidance of doubt, any Services so provided, (or to be so provided, as applicable), are Supplied.
          </p>
          <p>
            <strong>Tack Coat</strong>: any primer which may have to be used on a surface to enable the Product to be properly and permanently applied.
          </p>
          <p>
            <strong>1.1</strong> A reference to a particular law is a reference to it as it is in force for the time being taking account of any amendment, extension, application or re-enactment and  includes  any  subordinate legislation for the time being in force made under it.
          </p>
          <p>
            <strong>1.2</strong> Words in the singular include the plural and in the plural include the singular.
          </p>
          <p>
            <strong>1.3</strong> A reference to one gender includes a reference to the other gender.
          </p>
          <p>
            <strong>1.4</strong> Condition headings do not affect the interpretation of these Conditions.
          </p>
          <h4 class="pt-5">2. APPLICATION OF TERMS</h4>
          <p>
            <strong>2.1</strong> Subject to any variation under Condition 2.3 the Contract shall be on these Conditions to the exclusion of all other terms and conditions (including any terms or conditions  which  the  Customer purports to apply under any purchase order, confirmation of  order,  specification  or other document).
          </p>
          <p>
            <strong>2.2</strong> No terms or conditions endorsed on, delivered with or contained in  the  Customer's  purchase order, confirmation of order, specification or other document shall form part of  the  Contract simply as a result of such document being referred to in the Contract.
          </p>
          <p>
            <strong>2.3</strong> These Conditions apply to  the Supply of any and all Services by the Company and any variation   to these Conditions and any representations about any Services shall have no effect unless  expressly agreed in writing and signed by an authorised signatory of the Company. The Customer acknowledges that it has not relied on any statement, promise or representation made or given by  or on behalf of the Company which is not set out in the Contract. Nothing in this Condition shall exclude or limit the Company's liability for fraudulent misrepresentation.
          </p>
          <p>
            <strong>2.4</strong> Each order or acceptance of a Quotation for Services by the Customer from the Company shall be deemed to be an offer by the Customer to purchase Services subject to these Conditions.
          </p>
          <p>
            <strong>2.5</strong> No order placed by the Customer shall be deemed to be accepted by the Company until a written Acknowledgement of Order is issued by the Company.
          </p>
          <p>
            <strong>2.6</strong> Without prejudice to the provisions of Condition 2.5, if the Customer wishes to change any order, (which, for the avoidance of doubt shall include, but shall not be limited to, any amendment, addition or substitution with respect to any Input Material and/or any specification), any such change is subject to acceptance by the Company and the Customer must pay  any  additional charges associated with fulfilling the changed order, including a Minimum Charge in accordance with clause 5.4.
          </p>
          <p>
            <strong>2.7</strong> The Customer shall ensure that the terms of its order and any Input Material and/or any applicable specification are complete, accurate and fully comprehensible and are submitted to the Company within a sufficient time period such as will enable the Company to fulfil the Company’s  obligations under the Contract.
          </p>
          <p>
            <strong>2.8</strong> Any Quotation is given on the basis that no Contract shall come into existence until the Company despatches a written Acknowledgment of Order to the Customer. Any Quotation is valid  for  a period of 30 days only from its date, provided that the Company has not previously withdrawn it.
          </p>
          <h4 class="pt-5">3. DESCRIPTION AND SUPPLY OF SERVICES</h4>
          <p>
            <strong>3.1</strong> The Company shall supply the Services to the Customer as set out in the Acknowledgement of Order in all material respects.
          </p>
          <p>
            <strong>3.2</strong> The Company shall use all reasonable endeavours to meet any performance dates specified in the Acknowledgement of Order but:
          </p>
          <p class="pl-4">
            (a) any such dates shall be estimates only and time shall not be of the essence for performance of the Services.
          </p>
          <p class="pl-4">
            (b) the minimum lead time for the Supply of the Services is 10 to 14 days from Acknowledgement of Order.
          </p>
          <p class="pl-4">
            (c) if a specific date is required for the delivery of the Services this must be agreed in writing in the Acknowledgement of Order as a specific date and booked in as such in accordance with the Company’s process.
          </p>
          <p>
            <strong>3.3</strong> The Company reserves the right to amend the Services if necessary to comply with any applicable law or regulatory requirement, or if the amendment will not materially affect the nature or quality of the Services, and the Supplier shall notify the Customer in any such event.
          </p>
          <p>
            <strong>3.4</strong> The Company warrants to the Customer that the Services will be provided using reasonable care and skill. 
          </p>
          <p>
            <strong>3.5</strong> All drawings, descriptive matter, specifications and advertising issued by the Company and any descriptions or illustrations contained in the Company's website, catalogues or  brochures  are issued or published for  the sole purpose  of giving an approximate idea of the Services described  in them.
          </p>
          <p>
            <strong>3.6</strong> If any Services are to be performed by the Company in accordance with any Input Material and/or any specification submitted by the Customer, the Customer shall hold the Company harmless and shall fully indemnify the Company against any and all loss, damage, costs and expenses awarded against or incurred by the Company in connection with, or paid or agreed to be paid by, the Company, in settlement of any claim for infringement of  any patent,  copyright,  design,  trade mark or any other intellectual property right  of any other person resulting from the Company’s   use of any Input Material and/or any specification so submitted.
          </p>
          <h4 class="pt-5">4. COMMENCEMENT</h4>
          <p>
            <strong>4.1</strong> The Services supplied under the Contract shall be provided  by the  Company to  the Customer from the date of the Company’s Acknowledgement of Order in  accordance  with  Condition 2.5, unless otherwise specified by the Company.
          </p>
          <p>
            <strong>4.2</strong> Any dates quoted for performance of the Services are approximate only, and the time of performance is not of the essence. The Company shall not be liable for any delay in delivery of the Products that is caused by a Force Majeure Event or the Customer's failure to provide the Supplier with adequate delivery instructions or any other instructions that are relevant to the supply of the Products.
          </p>
          <h4 class="pt-5">5. CHARGES</h4>
          <p>
            <strong>5.1</strong> Unless otherwise agreed by the Company in writing and subject to any rate increase by virtue of any relevant provision of this Condition 5, the price for the Services shall be the price set out in the Company’s written Acknowledgment of Order pursuant to Condition 2.5 including, but not limited to remeasure as set out in 5.4 below.
          </p>
          <p>
            <strong>5.2</strong> For the avoidance of doubt the Charges payable for the Services are the higher of:
          </p>
          <p class="pl-4">
            (a) the Minimum Charge as detailed in clause 5.5 subject always to a remeasure as detailed in clause 5.4; or
          </p>
          <p class="pl-4">
            (b) a Day Rate as detailed in clause 5.6; or
          </p>
          <p class="pl-4">
            (c) a Fixed Price as detailed in clause 5.7
          </p>
          <p class="pl-4">
            together with any further Charge variation for the reasons set out in clauses 5.8 to 5.16.
          </p>
          <p>
            <strong>5.3</strong> The Charges shall be exclusive of value added tax.
          </p>
          <p>
            <strong>5.4 Remeasure</strong>
          </p>
          <p class="pl-4">
            (a) Charges set out in the Acknowledgement of Order are based upon the estimate provided based upon the information supplied by the Customer. The actual Charge made will be calculated after the Services have been Supplied and an accurate ‘measure’ of the lines painted has been made.
          </p>
          <p class="pl-4">
            (b) The Customer shall pay to the Company any additional sums which, in the Company’s sole discretion, are required as a result of the Customer’s instructions or lack of instructions, the inaccuracy of any Input Material or any other cause attributable to the Customer. 
          </p>
          <p class="pl-4">
            (c) Without limitation, the Company reserves the right to increase the price of the Services due to any change  in the Services requested by the Customer, or any delay caused by any instructions of  the  Customer or any failure of the Customer to give the Company adequate information or  instructions.
          </p>
          <p>
            <strong>5.5 Minimum Charges</strong>
          </p>
          <p class="pl-4">
            (a) A Minimum Charge applies to all quotations given. This is to cover the costs of mobilisation, labour, plant and materials up to the value of the measured work at the given rates and at one Site only. 
          </p>
          <p class="pl-4">
            (b) The Minimum Charge is not a ‘day rate’, it is made to cover the Company’s costs in mobilization a crew to undertake any particular job.
          </p>
          <p class="pl-4">
            (c) The Company reserves the   right to levy separate Minimum Charges where:
          </p>
          <p class="pl-8">
            (i) separate procedures are deployed e.g. mixed hot and cold laid materials,  for safety reasons; and / or
          </p>
          <p class="pl-8">
            (ii) The Services are not completed in one Site visit.
          </p>
          <p class="pl-4">
            (d) Without prejudice to the provisions of Conditions 2.5  or 2.6, the Company reserves the right to levy a Minimum Charge where the Customer cancels an order within 12 hours of the requested or intended start time for performance of the Services.
          </p>
          <p>
            <strong>5.6 Day Rate</strong>
          </p>
          <p class="pl-4">
            (a) A Day Rate for the Charges can be provided on request based on distance, time on Site (up to ten hours) and the hourly cost of  labour and plant. 
          </p>
          <p class="pl-4">
            (b) The Day Rate is currently £1,500 per day or as set out in the Acknowledgement of Order.
          </p>
          <p>
            (c) If a Day Rate is agreed it will be specifically identified on the Acknowledgement of Order.
          </p>
          <p>
            <strong>5.7 Fixed Price</strong>
          </p>
          <p class="pl-4">
            (a) A Fixed Price for the Charges can be agreed upon request based upon Services specified in the Quotation.
          </p>
          <p class="pl-4">
            (b) The Fixed Price shall not include any Services which the Customer may request in addition when the crew is on Site which are not identified in the Quotation. Any such extras shall be charged separately after a Remeasure has been undertaken.
          </p>
          <p>
            5.8 <strong><i>Waiting or non-productive time</i></strong> (whether or not caused by the Customer, but excluding that resulting from inclement weather conditions) will be charged at the hourly cost  of labour  and plant.
          </p>
          <p>
            5.9 Out of hours work (including weekend work) will incur an extra 20% charge unless otherwise agreed.
          </p>
          <p>
            5.10 Any surface requiring tack coat will incur an increase in rates of 50% unless otherwise stated.
          </p>
          <p>
            5.11 Setting-out marks in non-permanent crayon will disappear naturally, and removal of such marks  has not been provided  for in the Company’s  rates. The Company reserves the right to levy an  extra charge if the Company agrees to remove any setting-out marks.
          </p>
          <p>
            5.12 Rates quoted by the Company are based on quantities requested by the Customer. Any reduction  or increase in quantities requested is subject to acceptance  by the  Company and  if so  accepted, the rate previously quoted will be adjusted accordingly
          </p>
          <p>
            5.13 Coloured surfaces will be charged inclusive of masked areas where materials have to be naturally wasted.
          </p>
          <p>
            5.14 Force drying is not included in the Company’s rates unless otherwise stated. The  Company reserves the right to levy an extra 20% charge if the Company agrees to undertake any force drying, without any guarantee as to the performance of the materials
          </p>
          <p>
            5.15 Coverage rates for application of resins to bituminous surfaces are dependent on texture and age. Bitumen bleed will be problematic on surfaces less than 28 days old.  Rates may vary considerably from those quoted if the Company is instructed to lay on new surfaces and a sample will be applied to determine the required coverage.
          </p>
          <p>
            5.16 Lines that are laid to meet the kerb edge will fall short because of the logistics of machine laying, but are extendible by hand to meet the kerb edge, on request only and at extra cost.
          </p>
          <h4 class="pt-5">6. Marking Materials</h4>
          <p class="pl-4">
            (a) Marking Materials will not be applied to surfaces in conditions outside the manufacturer’s specification (ie where the surface is not clean or dry, or the temperature is less than 5 degrees C) unless a disclaimer has been signed by the Customer. 
          </p>
          <p class="pl-4">
            (b) Without limitation, the Company shall not be liable for Services performed in conditions which are not in accordance  with the Products’ manufacturer’s specification.
          </p>
          <p class="pl-4">
            (c) The Company shall not be responsible for removing any redundant markings unless this is stipulated in the Acknowledgment of Order. Removal of redundant markings will be carried out by the most appropriate method (as determined by the Company, in its sole discretion) and the rate stipulated in the Company’s Acknowledgment of Order shall  be  to remove a maximum thickness  of materials of 3mm (the rate for anything in excess of 3mm shall be subject to  further negotiation). 
          </p>
          <p class="pl-4">
            (d) <strong><i>Hydroblasting Services</i></strong>: the removal of redundant markings by using hydroblasting is an inherently aggressive process removing a layer of the existing surface as well as the markings themselves. Sometimes the markings cannot be removed completely without causing undue damage to the underlying surface. The Customer must acknowledge that, depending upon the existing surface, there will be a degree of scarring involved. Accordingly, without limitation, the Company does not guarantee the complete erasure of all markings on all surfaces, nor shall the Company be liable for any surface scarring which may result.
          </p>
          <p class="pl-4">
            (e) Setting-out marks in non-permanent crayon will disappear naturally, and removal of such marks  has not been provided  for in the Company’s  rates. The Company reserves the right to levy an  extra charge if the Company agrees to remove any setting-out marks.
          </p>
          <p class="pl-4">
            (f) Block paving, concrete, very smooth power floated surfaces or surfaces which are less than 3 months old are not considered to be suitable surfaces for the application of marking materials and the performance of marking materials applied to any of these surfaces cannot be guaranteed. With thermoplastic marking materials, which are formulated to bond to bituminous surfaces, it is not generally possible to achieve adequate adhesion (even if tack coats are used) as a relative movement between surface blocks results in the thermoplastic cracking after a short period  of  time. Chlorinated rubber, acrylic or epoxy paints to road marking standards tends to wear out quickly due to the abrasive qualities of the surface blocks and the sand use in the gaps between blocks. Very smooth surfaces  do not provide the required mechanical  bond and any preparation   of the surface prior to the application of marking materials such as blasting/etching etc may compromise the surface structure. The Company requires that a disclaimer be signed before undertaking such work. Without limitation, the Company shall not be liable  for  Services  involving the application of marking materials to unsuitable surfaces.
          </p>
          <p class="pl-4">
            (g) Colour stability of marking materials cannot be guaranteed, as colour stability is dependent on location and environmental conditions. Lines will become dirty when used in an indoor environment and will require routine washing by the Customer.
          </p>
          <p class="pl-4">
            (h) When re-covering existing markings, the resulting finish will depend on the condition  and  integrity of the existing lines. Where parts of the original markings are damaged or missing, a variation in thickness will result. Customers should give special consideration as to the required overall thickness when lines are re-covered, as trip hazards and  drainage  problems  may result from the use of materials of inadequate thickness, for which the Company shall not be liable.
          </p>
          <p class="pl-4">
            (i) Any surface requiring tack coat will incur an increase in rates of 50% unless otherwise stated.
          </p>
          <p class="pl-4">
            (j) Paint will be applied by airless spray machine unless otherwise stated.  Customers  should  be aware that the finished line, whilst adequate for most applications, will  not  have edge lines as  crisp as when the lines are masked. A hand finished appearance can be achieved at extra cost.
          </p>
          <p>
            6.2 Marking Material / Product Performance and Product Guarantee
          </p>
          <p class="pl-4">
            (a) The Company will provide the Services using reasonable care and skill.
          </p>
          <p class="pl-4">
            (b) The performance of Marking Materials and durability of paint and paint based surface treatments cannot be guaranteed, as this is dependent on surface type, condition and the wear to which it is subjected. Accordingly the Company’s responsibility for the Products and Services is therefore limited to when any Product failure is due to their failure to provide the Services using reasonable care and skill.  
          </p>
          <p class="pl-4">
            (c) The Company is not responsible for Product failure or associated costs (for example, the costs of reapplication or other remedial costs) if:
          </p>
          <p class="pl-8">
            (i) Marking Materials are applied to unsound surfaces or
          </p>
          <p class="pl-8">
            (ii) where the Customer has not allowed Tack Coat to dry properly; or
          </p>
          <p class="pl-8">
            (iii) the Customer specifies a Product to be used the Customer is responsible for the Product choice. While the Company will use reasonable care and skill in applying the Product in accordance with the Customer’s or manufacturer’s instruction the Company will not be responsible if the Product fails for any other reason;
          </p>
          <p class="pl-8">
            (iv) the Customer insists on deviating from the manufacturer's or the Company's guidance / instructions on application, thickness, drying times etc
          </p>
          <p class="pl-8">
            (v) where and Product failure is due to inaccurate or misleading Input Materials.
          </p>
          <p class="pl-4">
            (d) Relevant humidity
          </p>
          <p class="pl-8">
            (i) The Company reserves the right to deem any untested concretious surface exceeding 3 months old satisfactory relating to the relative humidity levels for the preparation and application of marking materials commonly used within the business.
          </p>
          <p class="pl-8">
            (ii) In the event of material delamination failure or non-curing after the correct preparation method has been undertaken the responsibility for the testing of the substrate to ascertain relative humidity readings and moisture content lies with the Customer and not the Company.
          </p>
          <p class="pl-8">
            (iii) The Customer will be responsible for the testing if the relative humidity level is suspected to exceed the recommended levels detailed by the manufacturer and if levels are deemed in excess of the recommended limited detailed by the manufacturer.
          </p>
          <p class="pl-8">
            (iv) Whilst this issue rarely causes an issue, underlying failure of the moisture membrane within the substrate can lead to higher than permissible relative humidity readings, to which the company will mitigate their warranties and all monies due will be deemed payable.
          </p>
          <p class="pl-4">
            (e) Curing Agents and Contamination
          </p>
          <p class="pl-8">
            (i) In the event of material delamination, failure or non-curing after the correct preparation method has been undertaken, and the suspected cause is contamination of the substrate, whether that be preabsorbed sealers, chemicals or oils, historical or otherwise, testing to determine the type/level of contamination is the responsibility of the Customer.
          </p>
          <p class="pl-8">
            (ii) Whilst this issue rarely causes and issue, failure of the markings due to contamination within the substrate can lead to delamination, failure or non-curing of the markings, to which the Company will mitigate their warranties and all monies due will be deemed payable.
          </p>
          <p class="pl-8">
            (iii) Blockwork. If paint is applied to blocks and the blocks are porous, contaminated, suffer from efflorescence, or have been impregnated with chemicals during their production, then we cannot guarantee that the paint will adhere to the blocks.
          </p>
          <h4 class="pt-5">7. Management of traffic at the Site</h4>
          <p class="pl-4">
            (a) in order to enable the Services to be performed expeditiously management of traffic at the Site is not included in the provision of the Services (quotations are available upon request). 
          </p>
          <p class="pl-4">
            (b) Work cancelled, postponed or delayed due to lack of traffic management at the Site, or due to staff working conditions, will be subject to the Minimum Charge. It is the Customer’s responsibility to ensure that the work area at the Site is clear, including areas not to be marked but required for setting-out purposes. Without limitation, the Customer shall ensure that the Services can be performed at the Site in a continuous and uninterrupted manner
          </p>
          <h4 class="pt-5">8. Customer Obligations</h4>
          <p>
            The Customer shall:
          </p>
          <p class="pl-4">
            (a) ensure that the surface to which the Marking Materials are to be applied is in the Company’s reasonable opinion sound.
          </p>
          <p class="pl-4">
            (b) ensure that when the Company specify that a Tack Coat is required that it is allowed appropriate time to dry in accordance with guidance, that the surface onto which it is applied is protected and that no vehicle or pedestrian traffic is allowed on the surface before the Marking Materials are applied.
          </p>
          <p class="pl-4">
            (c) co-operate with the Company in all matters relating to the Services;
          </p>
          <p class="pl-4">
            (d) promptly provide the Company with such Input Material and other information as the Company may require;
          </p>
          <p class="pl-4">
            (e) obtain and maintain all necessary licences and consents and comply with all relevant legislation in relation to the Services and the use of any Input Material, in all  cases  before the date on which the Services are to start; and
          </p>
          <p class="pl-4">
            (f) keep and maintain any Output Material in good condition, and shall not dispose of or use the Output Material other than in accordance with the Company's  written  instructions or authorisation;
          </p>
          <p class="pl-4">
            (g) be  responsible for setting- out the works, or for overseeing and confirming    the correct setting- out of works by the Company’s personnel. Any errors in setting out  shall be  the Customer’s sole responsibility.
          </p>
          <p class="pl-4">
            (h) be solely responsible for ensuring the safety of any and all of  the  Customer’s  employees, invitees and licensees who are or may be present on the Site during the performance of the Services (both inside and outside normal business hours), including but  not limited to restricting access to those areas of the Site where the Services are or  are to be performed, to those individuals engaged in performing the Services, or  providing assistance to those so engaged; and
          </p>
          <p>
            (i) effect and maintain appropriate insurance in an adequate amount with respect to all possible risks which may arise in connection with the deployment of  any  person  engaged by the Company to perform the Services at the Site and shall, at the Company’s request, provide such evidence of such insurance as the Company may  reasonably  require
          </p>
          <p>
            8.2 If the Company's performance of its obligations under the Contract is prevented, delayed, suspended or otherwise adversely affected by any act or omission of the Customer, its agents, subcontractors, consultants or employees, the Company shall not be liable  for any costs,  charges or losses sustained or incurred by the Customer arising directly or indirectly from such prevention or delay.
          </p>
          <p>
            8.3 The Customer shall be liable to pay to the Company, on demand, all reasonable costs, charges or losses sustained or incurred by the Company (including, without limitation, any direct, indirect or consequential losses, loss of profit and loss of reputation, loss or damage to property and those arising from injury to or death of any person and loss of opportunity to deploy resources  elsewhere) arising directly or indirectly from the Customer's fraud, negligence, failure to perform  or delay in the performance of any of its obligations under the Contract, subject to the Company confirming such costs, charges and losses to the Customer in writing.
          </p>
          <p>
            8.4 The Customer shall hold the Company harmless and keep the Company  indemnified  in  full against all direct, indirect or consequential liabilities (all three of which terms include, without limitation, loss of profit, loss of business, depletion of goodwill and like loss), loss, damages, injury, costs and expenses (including legal and other professional fees and expenses) awarded against or incurred or paid by the Company as a result of or in connection with any claim made against the Company in respect of any liability, loss, damage, injury, cost or expense sustained by any third party to the extent that such liability, loss, damage, injury,  cost  or  expense  arises directly or indirectly from the Customer's fraud, negligence, failure to perform or delay in the performance of any of its obligations under the Contract, subject to the Company confirming such costs, charges and losses to the Customer in writing.
          </p>
          <p>
            8.5 The Customer shall obtain and maintain a suitable policy of insurance in an amount adequate to provide coverage for any and all of the risks contemplated by Conditions 8.3 and 8.4 and shall produce such policy of insurance for inspection by the Company upon request.
          </p>
          <p>
            8.6 The Customer shall not, without the prior written consent of the Company, at any time from the date of the Contract to the expiry of 12 months after the last date of supply of the Services or termination of the Contract, as applicable, solicit or entice away from the Company or employ (or attempt to employ) or otherwise engage or attempt to engage the services of any person who is, or has been, engaged as an employee, consultant or  subcontractor  of the Company in the provision  of the Services.
          </p>
          <p>
            8.7 Any consent given by the Company in accordance with Condition 8.6 shall be subject to the Customer paying to the Company a sum equivalent to 15 % of the then current annual  remuneration of the Company's employee, consultant or subcontractor.
          </p>
          <h4 class="pt-5">9. PAYMENT</h4>
          <p>
            9.1 All Customers will be credit checked by the Company and the Company will either approve or refuse credit, in the Company’s sole discretion. The price for the Services shall be paid by the Customer to the Company, as follows:
          </p>
          <p>
            9.1.1 If the Company has approved the Customer’s credit, the Company’s invoices must be paid within 30 days of the date of the relevant invoice; or
          </p>
          <p>
            9.1.2 If no credit terms have been approved, then full payment must be made when the Customer’s order is placed.
          </p>
          <p>
            9.2 Time for payment shall be of the essence.
          </p>
          <p>
            9.3 Without prejudice to Condition 9.1, the Company reserves the right to request staged payments and/or the payment of a deposit from time to time.
          </p>
          <p>
            9.4 No payment shall be deemed to have been received until the Company has  received  cleared funds. 
          </p>
          <p>
            9.5 In the event that the Customer tenders payment by cheque and said cheque is returned unpaid to the Company, the Customer shall reimburse the Company for any  bank  charges  incurred by the Company.
          </p>
          <p>
            9.6 All payments payable to the Company under the Contract shall become due immediately on its termination despite any other provision.
          </p>
          <p>
            9.7 The Customer shall make all payments due under the Contract in full without any deduction whether by way of set-off, counterclaim, discount, abatement or otherwise unless the Customer has a valid court order requiring an amount equal to such deduction to be paid by the Company to the Customer.
          </p>
          <p>
            9.8 If the Customer fails to pay the Company any sum due pursuant to the Contract, the Customer   shall be liable to pay interest to the Company on such sum from the due date for payment at the annual rate of 5 % above the base lending rate from time to time of Barclays Bank plc, accruing   on a daily basis until payment is made, whether before or after any judgment. The Company reserves the right to claim interest under the Late Payment of Commercial Debts (Interest) Act 1998. The Customer shall reimburse the Company for any and all costs incurred by the Company in recovering payment pursuant to these Conditions.
          </p>
          <p>
            9.9 Without prejudice to Condition 9.7, any failure by the Customer to pay the Company any sum by the due date for payment and/or the occurrence of any one or more of the events set forth in Condition 15.1 (d)-(j) shall entitle the Company, at any time and without notice to the Customer and without limitation to any other remedy available to the Company under these Conditions, the Contract, or otherwise:
          </p>
          <p class="pl-4">
            1. To suspend or cancel the performance of any Services;
          </p>
          <p class="pl-4">
            2. To withdraw or reduce any agreed monthly credit limit; and
          </p>
          <p class="pl-4">
            3. To treat the Contract as having been repudiated by the Customer.
          </p>
          <p>
            9.10 The Customer may not cancel any order for Services for which a written  Acknowledgment  of  Order has been issued by the Company pursuant to Condition 2.5 and if the Customer cancels, or purports to cancel any such order (or the Contract or any part thereof), then the Company may, by notice in writing to the Customer, elect to treat the Contract as repudiated.
          </p>
          <p>
            9.11 For the avoidance of doubt, and notwithstanding the exercise of any remedy by the Company in accordance with any other of these Conditions, the Contract, or otherwise, the Customer  shall remain liable to pay and shall  pay the Company at the Contract  rate, any and all payments subsisting at the relevant time.
          </p>
          <h4 class="pt-5">10. Quality of Products</h4>
          <p>
            10.1 The Company warrants that on delivery, and for a period of 12 months from the date of delivery <strong>(Warranty Period)</strong>, the Products and Services shall:
          </p>
          <p class="pl-4">
            (a) shall conform in all material respects with their description; 
          </p>
          <p class="pl-4">
            (b) shall be free from material defects in design, material and workmanship; and 
          </p>
          <p class="pl-4">
            (c) be of satisfactory quality; and
          </p>
          <p class="pl-4">
            (d) be fit for any purpose held out by the Company.
          </p>
          <p>
            10.2 Subject to clause 10.3, if:
          </p>
          <p class="pl-4">
            (a) the Customer gives notice in writing to the Company during the Warranty Period within a reasonable time of discovery that some or all of the Products and Services do not comply with the warranty set out in clause 10.1; and
          </p>
          <p class="pl-4">
            (b) the Company is given a reasonable opportunity of examining such Products and Services;
          </p>
          <p>
            the Company shall, at its option, remedy the defect.
          </p>
          <p>
            10.3  The Company shall not be liable for the Products' or Service’s failure to comply with the warranty set out in clause 10.1 if: 
          </p>
          <p class="pl-4">
            (a) The defect arises as a result of a defect in the Marking Materials where such defect arises outside the Marking Material’s manufacturer’s standard warranty period;
          </p>
          <p class="pl-4">
            (b) the defect arises because the Customer failed to follow the Company's oral or written instructions as to the commissioning, use or maintenance of the Products or Services or good trade practice regarding the same;
          </p>
          <p class="pl-4">
            (c) the defect arises as a result of the Company following any drawing, design or specification supplied by the Customer;
          </p>
          <p class="pl-4">
            (d) the Customer alters or repairs such Products without the written consent of the Company;
          </p>
          <p class="pl-4">
            (e) the defect arises as a result of fair wear and tear, willful damage, negligence, or abnormal working conditions; or
          </p>
          <p class="pl-4">
            (f) the Products differ from their description as a result of changes made to ensure they comply with applicable statutory or regulatory requirements.
          </p>
          <p>
            10.4 Except as provided in this clause 10, the Company shall have no liability to the Customer in respect of the Products' failure to comply with the warranty set out in clause 10.1.
          </p>
          <p>
            10.5 These Conditions shall apply to any repaired or replacement Products supplied by the Company.
          </p>
          <h4 class="pt-5">11. INTELLECTUAL PROPERTY RIGHTS</h4>
          <p>
            11.1 As between the Customer and the Company, all Intellectual Property Rights and all other rights in the Output Material shall be owned by the Company. The Company licenses all such rights to the Customer free of charge and on a non-exclusive, worldwide basis to such extent as is necessary to enable the Customer to make reasonable use of the Services. If the Company terminates the Contract under Condition 12.1 or the Contract terminates, as applicable, this licence shall automatically terminate.
          </p>
          <h4 class="pt-5">12. CONFIDENTIALITY AND THE COMPANY'S PROPERTY</h4>
          <p>
            12.1 The Customer shall keep in strict confidence all technical or commercial know-how, specifications, inventions, processes or initiatives which are of a confidential nature and  have  been disclosed to the Customer by the Company, its employees, agents, consultants or subcontractors and any other confidential information concerning the Services or the Company's business which the Customer may obtain.
          </p>
          <p>
            12.2 The Customer may disclose such information:
          </p>
          <p class="pl-4">
            (a) to its employees, officers, representatives, advisers, agents or subcontractors who need to know such information for the purposes of carrying out the Customer's obligations under the Contract; and
          </p>
          <p class="pl-4">
            (b) as may be required by law, court order or any governmental or regulatory authority.
          </p>
          <p>
            12.3 The Customer shall ensure that its employees, officers, representatives, advisers, agents or subcontractors to whom it discloses such information comply with this Condition 11.
          </p>
          <p>
            12.4 The Customer shall not use any such information for any purpose other than to perform its obligations under the Contract.
          </p>
          <p>
            12.5 Any and all Output Material, including but not limited to any materials, goods,  drawings, templates, specifications and data supplied by the Company to the Customer, shall be held by the Customer in safe custody at the Customer’s premises and at its own risk and maintained and kept  in good condition by the Customer until returned to the Company, and shall not be disposed of or used other than in accordance with the Company's written instructions or authorisation.
          </p>
          <h4 class="pt-5">13. LIMITATION OF LIABILITY - THE CUSTOMER'S ATTENTION IS PARTICULARLY DRAWN TO THIS CONDITION</h4>
          <p>
            13.1 Subject always to any additional limitation of the Company’s liability elsewhere in these Conditions, including but not limited to any such limitation in Condition 5, this Condition 13 sets out the entire financial liability of the Company (including any liability for the acts or omissions  of its employees, agents, consultants, and subcontractors) to the Customer in respect of:
          </p>
          <p class="pl-4">
            (a) any breach of the Contract;
          </p>
          <p class="pl-4">
            (b) any use made by the Customer of the Services, the Output Material, or any part of them or it; and
          </p>
          <p class="pl-4">
            (c) any representation, statement or tortious act or omission (including negligence) arising under or in connection with the Contract.
          </p>
          <p>
            13.2 All warranties, conditions and other terms implied by statute or common law are, to the fullest extent permitted by law, excluded from the Contract.
          </p>
          <p>
            13.3 Nothing in these Conditions limits or excludes the liability of the Company:
          </p>
          <p class="pl-4">
            (a) for death or personal injury resulting from negligence; or
          </p>
          <p class="pl-4">
            (b) for any damage or liability incurred by the Customer as a result of fraud or fraudulent misrepresentation by the Company; or
          </p>
          <p class="pl-4">
            (c) for any liability incurred by the Customer as a result of any breach by the Company of the condition as to title or the warranty as to quiet possession implied by section 2 of the Supply of Goods and Services Act 1982.
          </p>
          <p>
            13.4 Subject to Condition 13.2 and Condition 13.3
          </p>
          <p class="pl-4">
            (a) the Company shall not be liable for:
          </p>
          <p class="pl-8">
            (i) loss of profits; or
          </p>
          <p class="pl-8">
            (ii) loss of business; or
          </p>
          <p class="pl-8">
            (iii) depletion of goodwill and/or similar losses; or
          </p>
          <p class="pl-8">
            (iv) loss of anticipated savings; or
          </p>
          <p class="pl-8">
            (v) loss of goods; or
          </p>
          <p class="pl-8">
            (vi) loss of contract; or
          </p>
          <p class="pl-8">
            (vii) loss of use; or
          </p>
          <p class="pl-8">
            (viii) loss of corruption of data or information; or
          </p>
          <p class="pl-8">
            (ix) any special, indirect, consequential or pure economic loss, costs, damages, charges or expenses.
          </p>
          <p class="pl-4">
            (b) the Company's total liability in contract, tort (including negligence or breach  of  statutory duty), misrepresentation, restitution or otherwise arising in connection with the performance, or contemplated performance, of the Contract shall be limited to the Charges paid for the Services.
          </p>
          <h4 class="pt-5">14. DATA PROTECTION</h4>
          <p>
            The Customer acknowledges that any Personal Data contained in  any of the  Input Material  may be processed by and on behalf of the Company in connection with the Services, whether or not    the Personal Data relates to the Customer (which, for the purposes of this Condition 14, includes any employee, servant or agent of the Customer) or to another third party data subject. The Customer hereby expressly consents to any processing of the Customer’s Personal Data by the Company and undertakes to procure the consent of any other relevant third party data subject to any processing of their Personal Data, and shall provide such proof of said third party  data subject’s consent to such processing as may be requested by the Company.
          </p>
          <h4 class="pt-5">15. TERMINATION</h4>
          <p>
            15.1 Without prejudice to any other rights or remedies which the parties may have, either party may terminate the Contract without liability to the other immediately on giving notice to the other if:
          </p>
          <p class="pl-4">
            (a) the other party fails to pay any amount due under the Contract on the due date for payment and remains in default not less than seven days  after being notified in writing   to make such payment; or
          </p>
          <p class="pl-4">
            (b) the other party commits a material breach of any of the terms of the Contract  and  (if  such a breach is remediable) fails to remedy that breach within 30 days of that party  being notified in writing of the breach; or
          </p>
          <p class="pl-4">
            (c) the other party repeatedly breaches any of the terms of the Contract in such a manner as  to reasonably justify the opinion that its conduct is inconsistent with it having the intention or ability to give effect to the terms of the Contract; or
          </p>
          <p class="pl-4">
            (d) the other party suspends, or threatens to suspend, payment of its debts or is unable to    pay its debts as they fall due or admits inability to pay its debts or (being a company) is deemed unable to pay its debts within the meaning of section 123 of the Insolvency Act 1986 or (being a natural person) is deemed either unable to pay its debts or as having no reasonable prospect of so doing, in either case, within the meaning of section 268 of the Insolvency Act 1986 or (being a partnership) has any partner to whom any of the foregoing apply; or
          </p>
          <p class="pl-4">
            (e) the other party commences negotiations with all or any class of its creditors with a view to rescheduling any of its debts, or makes a proposal  for or enters into any compromise  or arrangement with its creditors other than for the sole purpose of a scheme for  a  solvent amalgamation of that other party with one or more other companies  or  the solvent reconstruction of that other party; or
          </p>
          <p class="pl-4">
            (f) a petition is filed, a notice is given, a resolution is passed, or an order is made, for or on connection with the winding up of that other party other than for the sole purpose of a scheme for a solvent amalgamation of that other party with one or  more  other  companies or the solvent reconstruction of that other party;
          </p>
          <p class="pl-4">
            (g) an application is made to court, or an order is made, for the appointment of an administrator or if a notice of intention to appoint an administrator is given or if an administrator is appointed over the other party; or
          </p>
          <p class="pl-4">
            (h) a floating charge holder over the assets of that other party has  become  entitled  to appoint or has appointed an administrative receiver; or
          </p>
          <p class="pl-4">
            (i) a person becomes entitled to appoint a receiver over the assets of the other party or a receiver is appointed over the assets of the other party; or
          </p>
          <p class="pl-4">
            (j) a creditor or encumbrancer of the other party attaches or takes possession of, or  a distress, execution, sequestration or other such process is levied or enforced on or sued against, the whole or any part of its assets and such attachment or process is not discharged within 14 days; or
          </p>
          <p class="pl-4">
            (k) any event occurs, or proceeding is taken, with respect to the other party in any  jurisdiction to which it is subject that has an effect equivalent or similar to any of the events mentioned in Condition 15.1(d) to  Condition  15.1(j) (inclusive); or
          </p>
          <p class="pl-4">
            (l) the other party suspends or ceases, or threatens to suspend or cease, to carry on all or a substantial part of its business; or
          </p>
          <p class="pl-4">
            (m) there is a change of control of the other party (as defined in section 574 of the Capital Allowances Act 2001).
          </p>
          <p>
            15.2 On termination of the Contract for any reason:
          </p>
          <p class="pl-4">
            (a) the Customer shall immediately pay to the Company all of the Company's outstanding unpaid invoices and interest and, in respect of Services supplied but for  which  no  invoice has been submitted, the Company may submit an invoice, which  shall  be  payable immediately on receipt;
          </p>
          <p class="pl-4">
            (b) the Customer shall, within a reasonable time, return all of the Company's  Output  Material to the Customer. If the Customer fails to do so,  then the Company may enter  the Customer's premises and take possession of it. Until the Output Material has been returned or repossessed, the Customer shall be solely responsible for its safe  keeping;  and
          </p>
          <p class="pl-4">
            (c) the accrued rights and liabilities of the parties as at termination and the continuation of any provision expressly stated to survive or implicitly surviving termination,  shall not   be affected.
          </p>
          <p>
            15.3 On termination of the Contract (however arising), the following Conditions shall survive and continue in full force and effect:
          </p>
          <p class="pl-4">
            (a) Condition 8;
          </p>
          <p class="pl-4">
            (b) Condition 9;
          </p>
          <p class="pl-4">
            (c) Condition 10;
          </p>
          <p class="pl-4">
            (d) Condition 15; and
          </p>
          <p class="pl-4">
            (e) Condition 25.
          </p>
          <h4 class="pt-5">16. FORCE MAJEURE</h4>
          <p>
            The Company shall have no liability to the Customer under the Contract if it is prevented from, or delayed in performing, its obligations under the Contract or from carrying on its business by acts, events, omissions or accidents beyond its reasonable control, including (without  limitation)  strikes, lock-outs or other industrial disputes (whether involving the workforce of the Company or any other party), failure of a utility service or transport network, pandemic, act of God, war, riot, civil commotion, malicious damage, compliance with any law or governmental  order, rule, regulation  or direction, accident, breakdown of plant or machinery, fire, flood, storm or default of any suppliers or subcontractors.
          </p>
          <h4 class="pt-5">17. VARIATION</h4>
          <p>
            17.1 The Company may, from time to time and without notice, change the Services in order to comply with any applicable safety or statutory requirements, provided that such changes do not materially affect the nature, scope of, or the charges for the Services.  If the Company requests a change to   the scope of the Services for any other reason, the Customer shall not unreasonably withhold or delay consent to it.
          </p>
          <p>
            17.2 Subject to Condition 17.1, no variation of the Contract or these Conditions shall be valid unless it  is in writing and signed by or on behalf of each of the parties.
          </p>
          <h4 class="pt-5">18. WAIVER</h4>
          <p>
            18.1 A waiver of any right under the Contract is only effective if it is in writing and it applies only to the circumstances for which it is given. No failure or delay by a party in exercising any right or remedy under the Contract or by law shall constitute a waiver of that (or any other) right  or remedy, nor preclude or restrict its further exercise. No single or partial exercise of such right or remedy shall preclude or restrict the further exercise of that (or any other) right or remedy.
          </p>
          <p>
            18.2 Unless specifically provided otherwise, rights arising under the Contract are cumulative and do  not exclude rights provided by law.
          </p>
          <h4 class="pt-5">19. SEVERANCE</h4>
          <p>
            19.1 If any provision of the Contract (or part of any provision) is found by any court or other authority  of competent jurisdiction to be invalid, illegal or unenforceable, that provision or part-provision shall, to the extent required, be deemed not to form part of the Contract, and the validity and enforceability of the other provisions of the Contract shall not be affected.
          </p>
          <p>
            19.2 If a provision of the Contract (or part of any provision) is found illegal, invalid or unenforceable, the provision shall apply with the minimum modification necessary to make it legal, valid and enforceable.
          </p>
          <h4 class="pt-5">20. ENTIRE AGREEMENT</h4>
          <p>
            20.1 The Contract constitutes the whole agreement between the parties and supersedes all previous agreements between the parties relating to its subject matter.
          </p>
          <p>
            20.2 Each party acknowledges that, in entering into the Contract, it has not relied on, and shall have no right or remedy in respect of, any statement, representation, assurance or warranty (whether made negligently or innocently) (other than for breach of contract).
          </p>
          <p>
            20.3 Nothing in this Condition shall limit or exclude any liability for fraud.
          </p>
          <h4 class="pt-5">21. ASSIGNMENT</h4>
          <p>
            21.1 The Customer shall not, without the prior written consent of the Company,  assign,  transfer, charge, mortgage, subcontract or deal in any manner with all or any of its rights or obligations under the Contract.
          </p>
          <p>
            21.2 The Company may at any time assign, transfer, charge, mortgage, subcontract or deal in any other manner with all or any of its rights under the Contract and may subcontract or delegate in any manner any or all of its obligations under the Contract to any third party or agent.
          </p>
          <p>
            21.3 Each party that has rights under the Contract is acting on its own behalf and not for the benefit of another person.
          </p>
          <h4 class="pt-5">22. NO PARTNERSHIP OR AGENCY</h4>
          <p>
            Nothing in the Contract is intended to, or shall be deemed to, constitute a partnership or joint venture of any kind between any of the parties, nor constitute any party the agent of another party for any purpose. No party shall have authority to act as agent for, or to bind, the other party in any way.
          </p>
          <h4 class="pt-5">23. RIGHTS OF THIRD PARTIES</h4>
          <p>
            A person who is not a party to the Contract shall not have any rights under or in connection with   it.
          </p>
          <h4 class="pt-5">24. COMMUNICATIONS</h4>
          <p>
            24.1 All communications between the parties about the Contract shall be in writing and delivered by hand or sent by pre-paid first class post or sent by fax or sent electronically via email.
          </p>
          <p class="pl-4">
            (a) (in case of communications to the Company) to its registered office or such changed address as shall be notified to the Customer by the Company; or
          </p>
          <p class="pl-4">
            (b) (in the case of the communications to the Customer) to the registered office of the addressee (if it is a company) or (in any other case) to any address of the Customer set  out in any document which forms part of the Contract or such other address as shall be notified to the Company by the Customer.
          </p>
          <p>
            24.2 Communications shall be deemed to have been received:
          </p>
          <p class="pl-4">
            (a) if sent by pre-paid first class post,  two days (excluding Saturdays, Sundays and bank   and public holidays) after posting (exclusive of the day of posting); or
          </p>
          <p class="pl-4">
            (b) if delivered by hand, on the day of delivery; or
          </p>
          <p class="pl-4">
            (c) if sent by fax on a working day prior to 4.00 pm, at the time of transmission and  otherwise on the next working day; or
          </p>
          <p class="pl-4">
            (d) if sent by email, within twenty-four hours of sending.
          </p>
          <p>
            Communications addressed to the Company shall be marked for the attention of a director and / or Mr. Simon Peaker.
          </p>
          <h4 class="pt-5">25. GOVERNING LAW AND JURISDICTION</h4>
          <p>
            25.1 The Contract, and any dispute or claim arising out of or in connection with it or its subject matter, shall be governed by, and construed in accordance with, the law of England and Wales.
          </p>
          <p>
            25.2 The parties irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim that arises out of, or in connection with, the Contract or  its subject matter.
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsConditions',
};
</script>
